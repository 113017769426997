import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const OverPage = () => (
  <Layout>
    <SEO
      title="Verkoopsvoorwaarden"
      keywords={[
        `Yves De Vrient`,
        `Loodgieter Yves De Vrient`,
        `De Vrient`,
        `Vrient`,
        `Loodgieter De Vrient`,
        `loodgieter`,
        `loodgieter Kruisem`,
        `loodgieter Zingem`,
        `Loodgieter Oudenaarde`,
        `Verkoopsvoorwaarden`,
        `Algemene Verkoopsvoorwaarden`,
        `Frans De Vrient`,
      ]}
    />
    <h1>Algemene Verkoopsvoorwaarden</h1>
    <ol style={{
      marginLeft: '1rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.75rem'
    }}>
      <li>
        <p>
          Onderhavige voorwaarden maken integraal deel uit van de overeenkomst.
          Er kan alleen schriftelijk van worden afgeweken. Zij zijn van
          toepassing met uitsluiting van alle algemene voorwaarden voorkomend op
          de documenten uitgaande van de co-contractant/klant voor zover deze
          laatste voorwaarden in strijd zijn met de eerste.
        </p>
      </li>
      <li>
        <p>
          Om rechtsgeldig en ontvankelijk te zijn dienen alle klachten
          betreffende de geleverde goederen of betreffende de uitgevoerde werken
          ons aangetekend en gedetailleerd te bereiken binnen de 8 dagen
          respectievelijk na de levering of uitvoering der werken en de
          plaatsing van de goederen. Alle klachten betreffende de facturen
          dienen ons schriftelijk gedetailleerd te bereiken binnen de 8 dagen na
          factuurdatum.
        </p>
      </li>
      <li>
        <p>
          Onze offerten zijn vrijblijvend en worden gedaan zonder verbintenis.
          Leveringstermijnen worden steeds bij benadering opgegeven. Het
          verstrijken van de leveringsdatum geeft de klant niet het recht om de
          overeenkomst te annuleren noch om schadevergoeding te eisen.
        </p>
      </li>
      <li>
        <p>
          Behoudens andersluidende overeenkomst of andersluidende melding op de
          factuur, zijn al onze facturen contant betaalbaar.
        </p>
      </li>
      <li>
        <p>
          In geval van niet-tijdige betaling van de factuur, zal deze van
          rechtswege en zonder voorafgaande ingebrekestelling verhoogd worden,
          een nalatigheidsinterest van 12% op jaarbasis. In dat geval zal
          eveneens van rechtswege en zonder voorafgaande ingebrekestelling een
          bedrag van 20% van het factuurbedrag verschuldigd zijn met een minimum
          van 62,00€ en dit ten titel van forfaitaire schadevergoeding.
        </p>
      </li>
      <li>
        <p>
          Bij niet-tijdige betaling van een factuur zullen alle ander nog niet
          vervallen schuldvorderingen op de klant van rechtswege en zonder
          voorafgaande ingebrekestelling onmiddellijk opeisbaar worden. Tevens
          behouden wij ons het recht voor om verdere leveringen en werken stop
          te zetten, waarbij alle verantwoordelijkheid voor de klant is en
          zonder dat de klant hiervoor enige vergoeding van ons kan eisen. Ook
          behouden wij ons het recht voor om de overeenkomst van rechtswege en
          zonder voorafgaande ingebrekestelling als ontbonden te beschouwen voor
          het geheel of voor het nog niet uitgevoerde gedeelte. Dit alles
          onverminderd het betalen van schadevergoeding door de in gebreke
          gebleven klant.
        </p>
      </li>
      <li>
        <p>
          Wij behouden ons het recht voor de overeenkomst van rechtswege en
          zonder voorafgaande ingebrekestelling als ontbonden te beschouwen in
          geval van faillissement , kennelijk onvermogen alsook bij het even
          welke wijziging aan de juridische toestand van de koper/klant.
        </p>
      </li>
      <li>
        <p>
          De geleverde goederen blijven onze eigendom zolang ze niet volledig
          zijn betaald. Het risico gaat echter over op de klant van bij de
          contractsluiting. De goederen worden vervoerd op kosten en op risico
          van de klant.
        </p>
      </li>
      <li>
        <p>
          Tenzij andersluidend schriftelijk beding, wordt overeengekomen dat
          geen bemerkingen aangetekend werden overgemaakt, de aanneming aanvaard
          te zijn. Dit tijdstip word dan ook gelijkgesteld met de enige en
          definitieve oplevering.
        </p>
      </li>
      <li>
        <p>
          Onze vrijwaringsplicht m.b.t gebreken in de geleverd goederen strekt
          zich niet verder uit dan deze van onze leveranciers.
        </p>
      </li>
      <li>
        <p>
          In geval van betwisting zijn enkel de rechtbanken van het
          arrondissement Oudenaarde bevoegd. Al onze overeenkomsten worden
          beheerst door het Belgisch recht.
        </p>
      </li>
    </ol>
  </Layout>
)

export default OverPage
